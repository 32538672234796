import React from "react";
import ZavetZdarma from "../templates/ZavetZdarmaTemplate";
import ZivotDetemLogo from "../assets/img/zivotdetem.png";
import styled from "styled-components";


const TopPanelInner = styled.section`
  border-bottom: 1px solid #DFDFDF;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* border-top: 5px solid red; */
  background: rgb(255, 255, 218);
  height: fit-content;
  padding: 20px 0;


  @media (max-width: 580px) {
    padding: 30px 0;
  }


  div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: calc(100% - 50px);

    @media (max-width: 580px) {
      display: block;
    }
  }


  img {
    height: 100px;
    top: 10px;
    left: calc((100% - 1150px)/2);
    position: static;
  }


  p {
    font-family: Visuelt-Medium;
    font-size: 18px;
    color: #000000;
    max-width: 610px;
    line-height: 26px;
    text-align: left;
    margin-left: 30px;

    @media (max-width: 580px) {
      margin: 0;
      margin-top: 00px;
    }
  }

`;

const TopPanel = () => {
  return (
    <TopPanelInner>
      <div>
        <img src={ZivotDetemLogo} alt="Život dětem logo" />
        <p>Děkujeme, že jste si vybrali Goodbye na sepsání vaší závěti. Ve spolupráci s organizací ŽIVOT DĚTEM ji můžete vytvořit zdarma (běžně 2 000 Kč).</p>
      </div>
    </TopPanelInner>
  );
}



const ZivotDetem = () => {

  return (
    <ZavetZdarma
      topPanel={<TopPanel/>} 
      url="zivotdetem"
      charityTitle="ŽIVOT DĚTEM"
      h1="Vytvořte si vlastní platnou závěť online"
      desc={<p>Jsme hrdým partnerem organizace ŽIVOT DĚTEM. Pro uplatnění své závěti zdarma zadejte slevový kód “<span>zivotdetem</span>” při platbě.</p>}
    />
  );
}

export default ZivotDetem;
